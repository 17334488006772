import { render, staticRenderFns } from "./Addition.vue?vue&type=template&id=150e2474&scoped=true&"
import script from "./Addition.vue?vue&type=script&lang=ts&"
export * from "./Addition.vue?vue&type=script&lang=ts&"
import style0 from "./Addition.vue?vue&type=style&index=0&id=150e2474&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "150e2474",
  null
  
)

export default component.exports