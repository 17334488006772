import { Component, Vue } from 'vue-property-decorator';
import { getRandomElem } from  '@/plugins/maths';
import { g1, g2, g3, g4 } from '@/data/verbs.json'
import CardResult from '@/components/CardResult.vue'

@Component({
    metaInfo() { 
        return { 
            title: "Conjugaison : présent de l'indicatif",
            meta: [
                { name: 'description', content: "Générateur d'exercices de conjugaison" }
            ]
        } 
    },
    components: {CardResult}
})
export default class ChampionConjugaison extends Vue {

    public form: any = {}
    public items: any = []
    public show: boolean = true
    public person: string[] = ['Je', 'Tu', 'Il', 'Elle', 'On', 'Nous', 'Vous', 'Ils', 'Elles']

    public created() {
        this.reset()
    }

    public reset() {
        this.form = {
            lineNumber: 10,
            first: true,
            second: false,
            third: false,
            fourth: false,
            shuffle: false
         }
    }

    public onSubmit(evt: any) {
        evt.preventDefault()
        this.generate()
        this.$emit('newLines', this.items)
    }

    public onReset(evt: any) {
        evt.preventDefault()
        this.reset()
        // Trick to reset/clear native browser form validation state
        this.show = false
        this.$nextTick(() => {
            this.show = true
        })
    }

    public generate() {
        this.items = []
        let first: any = []
        let second: any = []
        let third: any = []
        let fourth: any = []

        let optionsNumber = [this.form.first, this.form.second, this.form.third, this.form.fourth].filter(Boolean).length;
        let lines = {
            number: Math.floor(this.form.lineNumber/optionsNumber),
            rest: this.form.lineNumber % optionsNumber,
            restUsed: false
        }

        if (this.form.first) {
            first = this.generateRegular(g1, this.getLines(lines))
        }
        if (this.form.second) {
            second = this.generateRegular(g2, this.getLines(lines))
        }
        if (this.form.third) {
            third = this.generateSimple(g3, this.getLines(lines))
        }
        if (this.form.fourth) {
            fourth =this.generateSimple(g4, this.getLines(lines))
        }
        
        this.items = [...first, ...second, ...third, ...fourth]
        if (this.form.shuffle) {
            this.items.shuffle()
        }
    }

    private generateRegular(arr: string[], lines: number) {
        let result: any = []
        for (let i = 0; i < lines;  i++) {
            let person = getRandomElem(this.person)
            let verb = getRandomElem(arr)
            let base = verb.slice(0, -2)
            result.push({person: person, base: base, verb: verb})
        }
        return result
    }

    private generateSimple(arr: string[], lines: number) {
        let result: any = []
        for (let i = 0; i < lines;  i++) {
            let person = getRandomElem(this.person)
            let verb = getRandomElem(arr)
            result.push({person: person, base: '', verb: verb})
        }
        return result
    }

    private getLines(lines: any) {
        if (lines.rest > 0 && !lines.restUsed) {
            lines.restUsed = true
            return lines.number + lines.rest
        }
        return lines.number
    }

}
