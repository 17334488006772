import { IPage, Page } from '@/models/Page';
import { Component, Vue } from 'vue-property-decorator';
import ExercisePreview from '@/components/ExercisePreview.vue'
import ContentSaveOutlineIcon from 'vue-material-design-icons/ContentSaveOutline.vue';
import DeleteOutlineIcon from 'vue-material-design-icons/DeleteOutline.vue';
import PlusCircleOutlineIcon from 'vue-material-design-icons/PlusCircleOutline.vue';
import FolderOutlineIcon from 'vue-material-design-icons/FolderOutline.vue';
import PrinterIcon from 'vue-material-design-icons/Printer.vue';
import { IExercise } from '@/models/Exercise';
import { namespace } from 'vuex-class';
const ExoStoreHelper = namespace('Exercises');
import VueGridLayout, { Resizable } from 'vue-grid-layout';
import { Layout } from '@/models/Layout';
import ModalPrint from '@/views/ModalPrint.vue'


@Component({
    metaInfo() {
        return {
            title: 'Créer une page',
            meta: [
                { name: 'description', content: "Créer et imprimer une page d'exercices facilement et rapidement." }
            ]
        }
    },
    components: {
        ExercisePreview, ContentSaveOutlineIcon, PlusCircleOutlineIcon, DeleteOutlineIcon, FolderOutlineIcon, PrinterIcon,
        GridLayout: VueGridLayout.GridLayout,
        GridItem: VueGridLayout.GridItem,
        Resizable, ModalPrint
    }
})
export default class Pages extends Vue {
    
    public page: IPage = new Page()
    public mousePos: {"x": number; "y": number} = {"x": 0, "y": 0}
    public dragPos: {"x": number, "y": number, "w": number, "h": number, "i": string} = {"x": 0, "y": 0, "w": 1, "h": 1, "i": ""}
    public COL_NUM: number = 2
    public ROW_HEIGHT: number = 5
    public printing: boolean = false

    $refs!: {
        gridlayout: any
    }

    public created() {
        if (this.currentPage) {
            let foundPage = this.pages.find(page => page.id === this.currentPage)
            if (foundPage) {
                this.page = foundPage
            }
        }
        this.currentPage = this.page.id!
    }

    public mounted() {
        document.addEventListener("dragover", e => {
            this.mousePos.x = e.clientX;
            this.mousePos.y = e.clientY;
        }, false);
        this.resetActive()
    }

    get exercises(): IExercise[] {
        return this.$store.state.Exercises.exercises
    }

    get pages(): IPage[] {
        return this.$store.state.Pages.pages
    }

    get currentPage(): number {
        return this.$store.state.Pages.currentPage
    }
    set currentPage(id) {
        this.$store.commit('Pages/updateCurrentPage', id)
    }

    @ExoStoreHelper.Getter
    public getExercisesFromIds!: (ids: number[]) => IExercise[]
    
    public updatePage() {
        this.$store.dispatch('Pages/savePage', this.page)
    }

    // EDITOR

    public addExerciseToPage(exo: IExercise) {
        this.page.exercises!.push(new Layout(0, 0, 1, 10, exo.id!, false))
        this.updatePage()
    }

    public removeExercise(id: string) {
        let index = this.page.exercises!.findIndex(layout => layout.i === id)
        if (index > -1) {
            this.page.exercises!.splice(index, 1)
        }
        this.updatePage()
    }
    
    public setActive(id: string) {
        this.page.exercises!.forEach(layout => layout.active = layout.i === id)
    }

    public resetActive() {
        this.page.exercises!.forEach(el => el.active = false)
    }
    
    // TOOLBAR FEATURES //
    
    public loadPageIndex(index: number) {
        if (index <= this.pages.length) {
            this.page = this.pages[index]
            this.currentPage = this.page.id!
        }   
    }
    
    public openPrinter() {
        let modalPrintMustbeShown = !this.$store.state.modalPrintShown
        if (modalPrintMustbeShown && navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
            this.$bvModal.show('modal-warn-print')
        } else {
            window.print()
        }
    }

    public deletePage() {
        this.$store.dispatch('Pages/deletePage', this.page).then(() => {
            this.showToast('La page a été suppriméé !')
            this.newPage()
        }).catch(() => {
            this.showToast('Il n\'y a rien a supprimer.')
        })
    }
    
    public newPage() {
        this.page = new Page()
        this.currentPage = this.page.id!
    }

    // UTILITY //

    private showToast(text: string) {
        Vue.toasted.show(text, {duration: 1500, position: 'top-center', theme: 'bubble'});
    }

    // DRAG EXO TO VUE-GRID-LAYOUT

    public drag(e: any, exo: IExercise) {
        let parentRect = document.getElementById('grid-container')!.getBoundingClientRect();
        let index = this.page.exercises!.findIndex(layout => layout.i === 'drop');
        let mouseInGrid = this.isMouseInGridlayout(parentRect);

        if (mouseInGrid === true && index === -1) {
            this.page.exercises!.push({
                x: (this.page.exercises!.length * 2) % (this.COL_NUM || 12),
                y: this.page.exercises!.length + (this.COL_NUM || 12), // puts it at the bottom
                w: 1,
                h: 1,
                exoId: exo.id!,
                active: false,
                i: 'drop'
            });
        }
        if (index !== -1) {
            try {
                this.$refs.gridlayout.$children[this.page.exercises!.length].$refs.item.style.display = "none";
            } catch {
                console.log("error")
            }
            let dropChild = this.$refs.gridlayout.$children[index];
            dropChild.dragging = {"top": this.mousePos.y - parentRect.top, "left": this.mousePos.x - parentRect.left};
            let newPos = dropChild.calcXY(this.mousePos.y - parentRect.top, this.mousePos.x - parentRect.left);
            if (mouseInGrid === true) {
                this.$refs.gridlayout.dragEvent('dragstart', 'drop', newPos.x, newPos.y, 1, 1);
                this.dragPos.i = String(index);
                this.dragPos.x = this.page.exercises![index].x;
                this.dragPos.y = this.page.exercises![index].y;
            }
            if (mouseInGrid === false) {
                this.$refs.gridlayout.dragEvent('dragend', 'drop', newPos.x, newPos.y, 1, 1);
                this.page.exercises! = this.page.exercises!.filter(layout => layout.i !== 'drop');
            }
        }
    }

    public dragend(e: any, exo: IExercise) {
        let parentRect = document.getElementById('grid-container')!.getBoundingClientRect();
        if (!parentRect) {
            return
        }
        let mouseInGrid = this.isMouseInGridlayout(parentRect);

        if (mouseInGrid === true) {
            this.$refs.gridlayout.dragEvent('dragend', 'drop', this.dragPos.x, this.dragPos.y, 1, 1);
            this.page.exercises! = this.page.exercises!.filter(layout => layout.i !== 'drop');

            this.page.exercises!.push(new Layout(this.dragPos.x, this.dragPos.y, 1, 1, exo.id!, false));

            this.$refs.gridlayout.dragEvent('dragend', this.dragPos.i, this.dragPos.x, this.dragPos.y, 1, 1);
            try {
                this.$refs.gridlayout.$children[this.page.exercises!.length].$refs.item.style.display = "block";
            } catch {
                console.log("error")
            }
        }
        this.updatePage()
    }

    private isMouseInGridlayout(parentRect: DOMRect) {
        return ((this.mousePos.x > parentRect.left) && (this.mousePos.x < parentRect.right)) 
                && ((this.mousePos.y > parentRect.top) && (this.mousePos.y < parentRect.bottom));
    }
}
