























import {Component, Vue} from 'vue-property-decorator'

@Component
export default class ModalPrint extends Vue {
  public modalPrintClosed() {
    this.$store.commit('setModalPrintShown');
    this.$bvModal.hide('modal-warn-print')
    window.print()
  }
}
