import { Component, Vue } from 'vue-property-decorator';
import { getRandomInt, getRandomIntInRange } from  '@/plugins/maths';
import CardResult from '@/components/CardResult.vue'

@Component({
    metaInfo() { return { title: "Compléments" } },
    components: {CardResult}
})
export default class ComplementGen extends Vue {

    private OP_PLUS: string = " + "
    private OP_EQUALS: string = " = "
    private OP_BLANK: string = ". . . ."

    public form: any = {}
    public items: any = []
    public show: boolean = true

    public created() {
        this.reset()
    }

    public reset() {
        this.form = {
            lineNumber: 10,
            cente: true,
            centaine: false,
            mille: false,
            millier: false,
            shuffle: false
         }
    }

    public onSubmit(evt: any) {
        evt.preventDefault()
        this.generateComplement()
        this.$emit('newLines', this.items)
    }

    public onReset(evt: any) {
        evt.preventDefault()
        this.reset()
        // Trick to reset/clear native browser form validation state
        this.show = false
        this.$nextTick(() => {
            this.show = true
        })
    }

    public generateComplement() {
        this.items = []
        let cente = []
        let centaine = []
        let mille = []
        let millier = []
        let optionsNumber = [this.form.cente, this.form.centaine, this.form.mille, this.form.millier].filter(Boolean).length;
        let lines = {
            number: Math.floor(this.form.lineNumber/optionsNumber),
            rest: this.form.lineNumber % optionsNumber,
            restUsed: false
        }

        if (this.form.cente) {
            cente = this.generate(this.getLines(lines), () => 100, () => getRandomInt(100), )
        }
        if (this.form.centaine) {
            centaine = this.generate(this.getLines(lines), () => getRandomIntInRange(1,9) * 100, () => getRandomInt(100))
        }
        if (this.form.mille) {
            mille = this.generate(this.getLines(lines), () => 1000, () => getRandomInt(1000))
        }
        if (this.form.millier) {
            millier = this.generate(this.getLines(lines), () => getRandomIntInRange(1,9) * 1000, () => getRandomInt(1000))
        }
        this.items = [...cente, ...centaine, ...mille, ...millier];
        
        if (this.form.shuffle) {
            this.items.shuffle()
        }
    }

    private getLines(lines: any) {
        if (lines.rest > 0 && !lines.restUsed) {
            lines.restUsed = true
            return lines.number + lines.rest
        }
        return lines.number
    }

    private generate(loop: number, f1: any, f2: any) {
        let arr: any = []
        for (let i = 0; i < loop; i++) {
            let result = f1()
            let right = f2()
            let left = result - right
            arr[i] = {left: left, op: this.OP_PLUS, right: this.OP_BLANK, result: result}
        }
        return arr
    }

}
