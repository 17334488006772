





































import { Component, Vue } from 'vue-property-decorator';
import CardResult from '@/components/CardResult.vue'
import { getRandomIntInRange } from  '@/plugins/maths';

@Component({
    metaInfo() { return { title: "Diviser un entier par X (et obtenir un nombre décimal)" } },
    components: {CardResult}
})
export default class DivResDecimal extends Vue {

    private OP_DIVIDE: string = " ÷ "
    private OP_EQUALS: string = " = "
    private OP_BLANK: string = ". . ."

    public form: any = {}
    public items: any = []
    public show: boolean = true

    public created() {
        this.reset()
    }

    public onSubmit(evt: any) {
        evt.preventDefault()
        this.generate()
        this.$emit('newLines', this.items)
    }

    public reset() {
        this.form = {
            lineNumber: 10,
            min: 1,
            max: 100,
            number: 10,
            cut: true,
            add: false
         }
    }

    public onReset(evt: any) {
        evt.preventDefault()
        this.reset()
        // Trick to reset/clear native browser form validation state
        this.show = false
        this.$nextTick(() => {
            this.show = true
        })
    }

    public generate() {
        this.items = []
        for (let i = 0; i < this.form.lineNumber; i++) {
            let left = getRandomIntInRange(this.form.min, this.form.max)
            if (left % this.form.number === 0) {
                left = getRandomIntInRange(this.form.min, this.form.max)
            }
            this.items.push({ left: left, op: this.OP_DIVIDE, right: this.form.number, result: this.OP_BLANK })
        }
        this.items.shuffle()
        this.items.splice(this.form.lineNumber)
    }

}
