import { Component, Vue } from 'vue-property-decorator';
import { getRandomIntInRange } from  '@/plugins/maths';
import CardResult from '@/components/CardResult.vue'

@Component({
    metaInfo() { return { title: "Multiplications par des multiples de 10" } },
    components: {CardResult}
})
export default class TenGen extends Vue {

    private OP_MULT: string = " × "
    private OP_EQUALS: string = " = "
    private OP_BLANK: string = ". . ."

    public form: any = {}
    public items: any = []
    public show: boolean = true

    public created() {
        this.reset()
    }

    public onSubmit(evt: any) {
        evt.preventDefault()
        this.generate()
        this.$emit('newLines', this.items)
    }

    public reset() {
        this.form = {
            lineNumber: 10,
            two: true,
            three: false,
            four: false,
            shuffle: false
         }
    }

    public onReset(evt: any) {
        evt.preventDefault()
        this.reset()
        // Trick to reset/clear native browser form validation state
        this.show = false
        this.$nextTick(() => {
            this.show = true
        })
    }

    public generate() {
        this.items = []
        let optionsNumber = [this.form.two, this.form.three, this.form.four].filter(Boolean).length;
        let lines = {
            number: Math.floor(this.form.lineNumber/optionsNumber),
            rest: this.form.lineNumber % optionsNumber,
            restUsed: false
        }
        
        if (this.form.two) {
            this.addLinesToItems(this.getLines(lines), 10);
        }
        if (this.form.three) {
            this.addLinesToItems(this.getLines(lines), 100);
        }
        if (this.form.four) {
            this.addLinesToItems(this.getLines(lines), 1000);
        }
        if (this.form.shuffle) {
            this.items.shuffle()
        }
    }

    private getLines(lines: any) {
        if (lines.rest > 0 && !lines.restUsed) {
            lines.restUsed = true
            return lines.number + lines.rest
        }
        return lines.number
    }

    private addLinesToItems(lines: number, mult: number) {
        let arr = [{}]
        for (let i = 0; i < lines; i++) {
            let left = getRandomIntInRange(1, 9) * mult;
            let right = getRandomIntInRange(1, 9);
            arr[i] = { left: left, op: this.OP_MULT, right: right, result: this.OP_BLANK };
        }
        this.items = [...this.items, ...arr];
    }
}
