export const Theme = Object.freeze({
    MALIN: { value: 'malin', text: 'Malin' },
    FLASH: { value: 'flash', text: 'Flash' },
    STRAT: { value: 'strat', text: 'Stratégique' },
    PROBL: { value: 'problem', text: 'Problème' },
    SIMPL: { value: 'simple', text: 'Simple' },
    ECO  : { value: 'eco', text: 'Eco impression'}
});

export const ExerciseFormat = Object.freeze({
    DEMI: { value: 'demi', text: 'Demi page' },
    FULL: { value: 'full', text: 'Pleine page' }
});

export const SectionFormat = Object.freeze({
    SIMPL: { value: 'simple', text: 'Simple' },
    DOUBL: { value: 'double', text: 'Double colonne' }    
});

