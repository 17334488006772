





































import { Component, Vue } from 'vue-property-decorator';
import CardResult from '@/components/CardResult.vue'

@Component({
    metaInfo() { return { title: "Critères de divisibilité" } },
    components: {CardResult}
})
export default class DividerCriteria extends Vue {

    private OP_DIVIDE: string = " ÷ "
    private OP_EQUALS: string = " = "
    private OP_BLANK: string = ". . ."

    public form: any = {}
    public items: any = []
    public show: boolean = true

    public created() {
        this.reset()
    }

    public onSubmit(evt: any) {
        evt.preventDefault()
        this.generate()
        this.$emit('newLines', this.items)
    }

    public reset() {
        this.form = {
            lineNumber: 10,
            min: 1,
            max: 100,
            divider: 2
         }
    }

    public onReset(evt: any) {
        evt.preventDefault()
        this.reset()
        // Trick to reset/clear native browser form validation state
        this.show = false
        this.$nextTick(() => {
            this.show = true
        })
    }

    public generate() {
        this.items = []

        let i = 1
        let allPossibleDividendsFound = i * this.form.divider > this.form.max
        let dividendsGood: any = []
        while (!allPossibleDividendsFound) {
            let dividend = i * this.form.divider
            if (dividend >= this.form.min) {
                dividendsGood.push(i * this.form.divider)
            }
            i++
            allPossibleDividendsFound = i * this.form.divider > this.form.max
        }
        dividendsGood.shuffle()

        i = 0
        while (i < this.form.lineNumber && i < dividendsGood.length) {
            let left = dividendsGood[i]
            let right = this.form.divider
            this.items.push({ left: left, op: this.OP_DIVIDE, right: right, result: this.OP_BLANK })
            i++
        }

        this.items.shuffle()
        this.items.splice(this.form.lineNumber, 1)
    }

}
