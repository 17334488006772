















import { Component, Vue, Prop } from 'vue-property-decorator';
import LinesTable from '@/views/LinesTable.vue'

@Component({
    components: {LinesTable}
})
export default class CardX extends Vue {

    private OP_PLUS: string = " + "
    private OP_EQUALS: string = " = "
    private BLANK_LINE: string = "______"

    @Prop({required: true})
    public items!: any[]

    public copy() {
        let str: string = ""
        if (this.isFrench) {
            this.items.forEach(el => str += el.person + ' ' + el.base + this.BLANK_LINE + ' (' + el.verb + ')'  + "\n")
        } else {
            this.items.forEach(el => str += this.lineToString(el) + "\n")
        }
        return str
    }

    private lineToString(el : any) {
        let str = ""
        if (el.left) { str += el.left }
        if (el.op) { str += el.op }
        if (el.right) { str = str + el.right + this.OP_EQUALS }
        if (el.result) { str += el.result }
        return str
    }

    get isFrench() {
        return this.items.length > 0 && !this.items[0].left && this.items[0].verb
    }
}
