import { Component, Vue } from 'vue-property-decorator';
import { getRandomInt, getRandomElem } from '@/plugins/maths';
import CardResult from '@/components/CardResult.vue'

@Component({
    metaInfo() { return { title: "Tables de multiplication" } },
    components: {CardResult}
})
export default class TableGen extends Vue {

  private OP_MULT: string = " × "
  private OP_EQUALS: string = " = "
  private OP_BLANK: string = ". . ."

    public form: any = {}
    public show: boolean = true
    public tablesOptions = [
        { text: '2', value: 2 },
        { text: '3', value: 3 },
        { text: '4', value: 4 },
        { text: '5', value: 5 },
        { text: '6', value: 6 },
        { text: '7', value: 7 },
        { text: '8', value: 8 },
        { text: '9', value: 9 }
      ]
      public tablesSelected: number[] = []
      public items: any = []
      private multiplier: number[] = [2,3,4,5,6,7,8,9]

      public created() {
        this.reset()
      }

      public onSubmit(evt: any) {
        evt.preventDefault()
        if (this.tablesSelected.length === 0) {
          return
        }
        this.generateTable()
        this.$emit('newLines', this.items)
      }

      public reset() {
        this.form = {
          lineNumber: 10,
          useZero: false,
          useOne: false,
          doNotUseDuplicate: false
        }
      }

      public onReset(evt: any) {
        evt.preventDefault()
        this.reset()
        // Trick to reset/clear native browser form validation state
        this.show = false
        this.$nextTick(() => {
          this.show = true
        })
      }

      public generateTable() {
        this.items = []
        let multiplier = [...this.multiplier]
        if (this.form.useZero) {
          multiplier.push(0)
        }
        if (this.form.useOne) {
          multiplier.push(1)
        }
        if (this.form.doNotUseDuplicate) {
          this.tablesSelected.forEach(table => {
            multiplier.forEach(mult => {
              this.items.push({left: table, op: this.OP_MULT, right: mult, result: this.OP_BLANK})
            })
          })
          this.items =  this.items.shuffle().slice(0, this.form.lineNumber)
        } else {
          for (let i = 0; i < this.form.lineNumber; i++) {
            let randTable = this.tablesSelected[getRandomInt(this.tablesSelected.length)]
            this.items[i] = {left: randTable, op: this.OP_MULT, right: getRandomElem(multiplier), result: this.OP_BLANK}
          }
        }
      }

}
