














































import { Component, Vue } from 'vue-property-decorator';
import CardResult from '@/components/CardResult.vue'
import { getRandomIntInRange, getRandomDecimalInRange, RandomEqui2 } from  '@/plugins/maths';

@Component({
    metaInfo() { return { title: "Additionner ou soustraire un entier à un décimal" } },
    components: {CardResult}
})
export default class AddcutDecimal extends Vue {

    private OP_PLUS: string = " + "
    private OP_MINUS : string = " - "
    private OP_EQUALS: string = " = "
    private OP_BLANK: string = ". . ."

    public form: any = {}
    public items: any = []
    public show: boolean = true

    public created() {
        this.reset()
    }

    public onSubmit(evt: any) {
        evt.preventDefault()
        this.generate()
        this.$emit('newLines', this.items)
    }

    public reset() {
        this.form = {
            lineNumber: 10,
            min: 1,
            max: 100,
            cut: true,
            add: false,
            dec1: true,
            dec2: false,
            dec3: false
         }
    }

    public onReset(evt: any) {
        evt.preventDefault()
        this.reset()
        // Trick to reset/clear native browser form validation state
        this.show = false
        this.$nextTick(() => {
            this.show = true
        })
    }

    public generate() {
        let options = [this.form.dec1, this.form.dec2, this.form.dec3]
                        .map((elem, index) => elem ? index + 1 : 0)
                        .filter(Boolean)
        
        let decimalsNumberGen = new RandomEqui2(options, this.form.lineNumber)
        let add: {}[] = []
        for (let i = 0; i < this.form.lineNumber; i++) {
            let left = getRandomDecimalInRange(this.form.min, this.form.max, decimalsNumberGen.getNext())
            let right = getRandomIntInRange(this.form.min, this.form.max)
            add.push({ left: left, op: this.OP_PLUS, right: right, result: this.OP_BLANK})
        }            

        let cut: {}[] = []
        decimalsNumberGen = new RandomEqui2(options, this.form.lineNumber)
        for (let i = 0; i < this.form.lineNumber; i++) {
            let left: any = getRandomDecimalInRange(this.form.min, this.form.max, decimalsNumberGen.getNext())
            let right = getRandomIntInRange(this.form.min, this.form.max)
            while (left < right) {
                right = getRandomIntInRange(this.form.min, this.form.max)
            }
            cut.push({ left: left, op: this.OP_MINUS, right: right, result: this.OP_BLANK })
        }

        this.items = []
        if (this.form.add) {
            this.items = [...this.items, ...add]
        }
        if (this.form.cut) {
            this.items = [...this.items, ...cut]
        }

        this.items.shuffle()
        this.items.splice(this.form.lineNumber)
    }

}
