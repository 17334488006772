import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { IExercise, Exercise } from '@/models/Exercise';

@Module({ namespaced: true })
export default class Exercises extends VuexModule {
  public exercises: Array<IExercise> = []
  public currentExercise: number = 0

  @Mutation
  public updateCurrentExercise(id: number) {
    this.currentExercise = id
  }

  @Mutation
  public addExercise(exercise: Exercise) {
    this.exercises.push(exercise)
  }

  @Mutation
  public updateExercise(exercise: Exercise) {
    let index = this.exercises.findIndex(p => p.id === exercise.id)
    if (index > -1) {
      this.exercises[index] = exercise
    }
  }

  @Mutation
  public delExercise(exercise: Exercise) {
    let index = this.exercises.findIndex(p => p.id === exercise.id)
    if (index > -1) {
      this.exercises.splice(index, 1)
    }
  }

  @Action
  public saveExercise(exercise: Exercise) {
    let index = this.exercises.findIndex(p => p.id === exercise.id)
    if (index > -1) {
      this.context.commit('updateExercise', exercise)
    } else {
      this.context.commit('addExercise', exercise)
    }
  }

  @Action
  public deleteExercise(exercise: Exercise) {
    return new Promise((resolve, reject) => {
      let index = this.exercises.findIndex(p => p.id === exercise.id)
      if (index > -1) {
        this.context.commit('delExercise', exercise)
        resolve()
      } else {
        reject()
      }
    })
  }

  get getExercise() {
    return (id: number) => {
      let index = this.exercises.findIndex(p => p.id === id)
      if (index > -1) {
          return this.exercises[index]
      }
      return {}
    }
  }

  get getExercisesFromIds() {
      return (ids: number[]) => {
        return ids.map(id => this.getExercise(id))
    };
  }

}
