



























































import { Component, Vue } from 'vue-property-decorator';
import { getRandomIntInRange } from  '@/plugins/maths';
import CardResult from '@/components/CardResult.vue'

@Component({
    metaInfo() { return { title: "Additions" } },
    components: {CardResult}
})
export default class Addition extends Vue {

    private OP_PLUS: string = " + "
    private OP_EQUALS: string = " = "
    private OP_BLANK: string = ". . ."

    public form: any = {}
    public items: any = []
    public show: boolean = true
    public advanced: boolean = false

    public created() {
        this.reset()
    }

    public onSubmit(evt: any) {
        evt.preventDefault()
        this.generate()
        this.$emit('newLines', this.items)
    }

    public reset() {
        this.form = {
            lineNumber: 10,
            one: true,
            two: false,
            three: false,
            shuffle: false,
            adv: [{right: {min: 1, max: 1}, left: {min: 1, max: 1}, lines: 10}]
         }
    }

    public onReset(evt: any) {
        evt.preventDefault()
        this.reset()
        // Trick to reset/clear native browser form validation state
        this.show = false
        this.$nextTick(() => {
            this.show = true
        })
    }

    public addAdvParam() {
        this.form.adv.push({right: {min: 2, max: 2}, left: {min: 2, max: 2}, lines: 10})
    }

    public removeLastAdvParam() {
        this.form.adv.pop()
    }

    public generate() {
        this.items = []

        if (this.advanced) {
            this.generateAdvanced()
        } else {
            this.generateSimple()
        }

        if (this.form.shuffle) {
            this.items.shuffle()
        }
    }

    private generateSimple() {
        let optionsNumber = [this.form.one, this.form.two, this.form.three].filter(Boolean).length;
        let lines = {
            number: Math.floor(this.form.lineNumber/optionsNumber),
            rest: this.form.lineNumber % optionsNumber,
            restUsed: false
        }
        
        if (this.form.one) {
            this.addLinesToItems(this.getLines(lines), 9);
        }
        if (this.form.two) {
            this.addLinesToItems(this.getLines(lines), 99);
        }
        if (this.form.three) {
            this.addLinesToItems(this.getLines(lines), 999);
        }
    }

    private generateAdvanced() {
        for (let i = 0; i < this.form.adv.length; i++) {
            this.addLinesToItemsFlex(this.form.adv[i].lines, this.form.adv[i].left, this.form.adv[i].right)
        }
    }

    private getLines(lines: any) {
        if (lines.rest > 0 && !lines.restUsed) {
            lines.restUsed = true
            return lines.number + lines.rest
        }
        return lines.number
    }

    private addLinesToItems(lines: number, max: number) {
        let arr = [{}]
        for (let i = 0; i < lines; i++) {
            let left = getRandomIntInRange(Math.ceil(max / 10), max);
            let right = getRandomIntInRange(Math.ceil(max / 10), max);
            arr[i] = { left: left, op: this.OP_PLUS, right: right, result: this.OP_BLANK };
        }
        this.items = [...this.items, ...arr];
    }

    private addLinesToItemsFlex(lines: number, leftParam: {min: number, max: number}, rightParam: {min: number, max: number}) {
        let arr = [{}]
        for (let i = 0; i < lines; i++) {
            let left = getRandomIntInRange(Math.pow(10, leftParam.min - 1), Math.pow(10, leftParam.max) -1);
            let right = getRandomIntInRange(Math.pow(10, rightParam.min - 1), Math.pow(10, rightParam.max) -1);
            arr[i] = { left: left, op: this.OP_PLUS, right: right, result: this.OP_BLANK };
        }
        this.items = [...this.items, ...arr];
    }
}
