import { IExercise } from './Exercise';
import { Layout } from './Layout';

export interface IPage {
  id?: number
  title?: string
  exercises?: Layout[]
  header?: {border: boolean, lastname: boolean, firstname: boolean, date: boolean, note: boolean, appreciation: boolean}
}

export class Page implements IPage {
  constructor(
    public id?: number,
    public title?: string,
    public exercises?: Layout[],
    public header?: {border: boolean, lastname: boolean, firstname: boolean, date: boolean, note: boolean, appreciation: boolean}
  ) {
    this.exercises = []
    this.id = Date.now() + Math.random()
    this.header = {border: false, lastname: true, firstname: true, date: true, note: true, appreciation: true}
  }
}
