
















































import { Component, Vue } from 'vue-property-decorator';
import { getRandomDecimalInRange, RandomEqui2 } from  '@/plugins/maths';
import CardResult from '@/components/CardResult.vue'

@Component({
    metaInfo() { return { title: "Calculer le complément d'un décimal à l'unité" } },
    components: {CardResult}
})
export default class ComplementDecimal extends Vue {

    private OP_PLUS: string = " + "
    private OP_EQUALS: string = " = "
    private OP_BLANK: string = ". . . ."

    public form: any = {}
    public items: any = []
    public show: boolean = true

    public created() {
        this.reset()
    }

    public reset() {
        this.form = {
            min: 1,
            max: 100,
            lineNumber: 10,
            dec1: true,
            dec2: false,
            dec3: false
         }
    }

    public onSubmit(evt: any) {
        evt.preventDefault()
        this.generateComplement()
        this.$emit('newLines', this.items)
    }

    public onReset(evt: any) {
        evt.preventDefault()
        this.reset()
        // Trick to reset/clear native browser form validation state
        this.show = false
        this.$nextTick(() => {
            this.show = true
        })
    }

    public generateComplement() {

        let options = [this.form.dec1, this.form.dec2, this.form.dec3]
                        .map((elem, index) => elem ? index + 1 : 0)
                        .filter(Boolean)
        
        let decimalsNumberGen = new RandomEqui2(options, this.form.lineNumber)
        this.items = []
        for (let i = 0; i < this.form.lineNumber; i++) {
            let left: any = getRandomDecimalInRange(this.form.min, this.form.max, decimalsNumberGen.getNext())
            let result = Math.floor(1 + parseFloat(left))
            this.items.push({ left: left, op: this.OP_PLUS, right: this.OP_BLANK, result: result})
        }            

        this.items.shuffle()
        this.items.splice(this.form.lineNumber)
    }

}
