















import {Component, Prop, Vue} from 'vue-property-decorator'

@Component
export default class LinesTable extends Vue {
  @Prop({required: true})
  public readonly line!: any

  public OP_EQUALS: string = " = "
  public OP_BLANK: string = "______"
}
