import { Component, Vue } from 'vue-property-decorator';
import { getRandomIntInRange } from  '@/plugins/maths';
import CardResult from '@/components/CardResult.vue'

@Component({
    metaInfo() { return { title: "Multiplications par 11" } },
    components: {CardResult}
})
export default class ElevenGen extends Vue {

    private OP_MULT: string = " × "
    private OP_EQUALS: string = " = "
    private OP_BLANK: string = ". . . ."

    public form: any = {}
    public items: any = []
    public show: boolean = true

    public created() {
        this.reset()
    }
    
    public onSubmit(evt: any) {
        evt.preventDefault()
        this.generate()
        this.$emit('newLines', this.items)
    }

    public reset() {
        this.form = {
            lineNumber: 10,
            one: true,
            two: false,
            retenue: false,
            doNotUseDuplicate: false,
            shuffle: false
         }
    }

    public onReset(evt: any) {
        evt.preventDefault()
        this.reset()
        // Trick to reset/clear native browser form validation state
        this.show = false
        this.$nextTick(() => {
            this.show = true
        })
    }

    public generate() {
        this.items = []
        let optionsNumber = [this.form.one, this.form.two, this.form.retenue].filter(Boolean).length;
        let lines = {
            number: Math.floor(this.form.lineNumber/optionsNumber),
            rest: this.form.lineNumber % optionsNumber,
            restUsed: false
        }

        if (this.form.doNotUseDuplicate) {
            this.generateNoDuplicate(lines);
        } else {
            this.generateNaive(lines);
        }
        if (this.form.shuffle) {
            this.items.shuffle()
        }
    }

    private generateNoDuplicate(lines: any) {

        let one: any = []
        let two: any = []
        let three: any = []
        for (let i = 1; i < 100; i++) {
            let left = i
            if (i < 10) {
                if (this.form.one) {
                    one.push(i)
                }
            } else if ((Math.floor(i / 10) + i % 10) < 10) {
                if (this.form.two) {
                    two.push(i)
                }
            } else if (this.form.retenue) {
                three.push(i)
            } 
        }
        one = one.shuffle().slice(0, lines.number + lines.rest)
        two = two.shuffle().slice(0, lines.number + lines.rest)
        three = three.shuffle().slice(0, lines.number + lines.rest)
        this.items = [...one, ...two, ...three].slice(0, this.form.lineNumber)
        for (let i = 0; i < this.items.length; i++) {
            this.items[i] = { left: this.items[i], op: this.OP_MULT, right: 11, result: this.OP_BLANK };
        }
    }

    private generateNaive(lines: any) {
        if (this.form.one) {
            let arr = [{}];
            for (let i = 0; i < lines.number + lines.rest; i++) {
                let left = getRandomIntInRange(1, 9);
                arr[i] = { left: left, op: this.OP_MULT, right: 11, result: this.OP_BLANK };
            }
            this.items = [...this.items, ...arr];
        }
        if (this.form.two) {
            let arr = [{}];
            for (let i = 0; i < lines.number + lines.rest; i++) {
                let decade = getRandomIntInRange(1, 9);
                let unit = getRandomIntInRange(0, 9 - decade);
                let left = decade * 10 + unit;
                arr[i] = { left: left, op: this.OP_MULT, right: 11, result: this.OP_BLANK };
            }
            this.items = [...this.items, ...arr];
        }
        if (this.form.retenue) {
            let arr = [{}];
            for (let i = 0; i < lines.number + lines.rest; i++) {
                let decade = getRandomIntInRange(1, 9);
                let unit = getRandomIntInRange(9 - decade + 1, 9);
                let left = decade * 10 + unit;
                arr[i] = { left: left, op: this.OP_MULT, right: 11, result: this.OP_BLANK };
            }
            this.items = [...this.items, ...arr];
        }
        this.items = this.items.slice(0, this.form.lineNumber)
    }
}
