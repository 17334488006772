
















































import { Component, Vue } from 'vue-property-decorator';
import CardResult from '@/components/CardResult.vue'
import { getRandomDecimalInRange, RandomEqui2 } from  '@/plugins/maths';

@Component({
    metaInfo() { return { title: "Diviser ou multiplier un nombre décimal par X" } },
    components: {CardResult}
})
export default class DivMultDecimal extends Vue {

    private OP_MULT: string = " × "
    private OP_DIVIDE: string = " ÷ "
    private OP_EQUALS: string = " = "
    private OP_BLANK: string = ". . ."
    private OPS = [this.OP_DIVIDE, this.OP_MULT]

    public form: any = {}
    public items: any = []
    public show: boolean = true

    public created() {
        this.reset()
    }

    public onSubmit(evt: any) {
        evt.preventDefault()
        this.generate()
        this.$emit('newLines', this.items)
    }

    public reset() {
        this.form = {
            lineNumber: 10,
            min: 1,
            max: 100,
            number: 10,
            div: true,
            mult: false,
            dec1: true,
            dec2: false,
            dec3: false
         }
    }

    public onReset(evt: any) {
        evt.preventDefault()
        this.reset()
        // Trick to reset/clear native browser form validation state
        this.show = false
        this.$nextTick(() => {
            this.show = true
        })
    }

    public generate() {
        let optionsDec = [this.form.dec1, this.form.dec2, this.form.dec3]
                        .map((elem, index) => elem ? index + 1 : 0)
                        .filter(Boolean)
        let decimalsNumberGen = new RandomEqui2(optionsDec, this.form.lineNumber)

        let optionsOp = [this.form.div, this.form.mult]
                        .map((elem, index) => elem ? index : -1)
                        .filter(e => e >= 0)
        let opNumberGen = new RandomEqui2(optionsOp,  this.form.lineNumber)

        this.items = []
        for (let i = 0; i < this.form.lineNumber; i++) {
            let left = getRandomDecimalInRange(this.form.min, this.form.max, decimalsNumberGen.getNext())
            this.items.push({ left: left, op: this.OPS[opNumberGen.getNext()], right: this.form.number, result: this.OP_BLANK })
        }
        this.items.shuffle()
        this.items.splice(this.form.lineNumber)
    }

}
