










































import { Component, Vue } from 'vue-property-decorator';
import CardResult from '@/components/CardResult.vue'
import { getRandomIntInRange } from  '@/plugins/maths';

@Component({
    metaInfo() { return { title: "Retrancher ou ajouter un nombre" } },
    components: {CardResult}
})
export default class Addcut extends Vue {

    private OP_PLUS: string = " + "
    private OP_MINUS : string = " - "
    private OP_EQUALS: string = " = "
    private OP_BLANK: string = ". . ."

    public form: any = {}
    public items: any = []
    public show: boolean = true

    public created() {
        this.reset()
    }

    public onSubmit(evt: any) {
        evt.preventDefault()
        this.generate()
        this.$emit('newLines', this.items)
    }

    public reset() {
        this.form = {
            lineNumber: 10,
            min: 1,
            max: 100,
            number: 9,
            cut: true,
            add: false
         }
    }

    public onReset(evt: any) {
        evt.preventDefault()
        this.reset()
        // Trick to reset/clear native browser form validation state
        this.show = false
        this.$nextTick(() => {
            this.show = true
        })
    }

    public generate() {
        this.items = []

        let add: {}[] = []
        let cut: {}[] = []
        for (let i = 0; i < this.form.lineNumber; i++) {
            let number = getRandomIntInRange(this.form.min, this.form.max)

            cut.push({ left: number, op: this.OP_MINUS, right: this.form.number, result: this.OP_BLANK })

            number = getRandomIntInRange(this.form.min, this.form.max)
            add.push({ left: number, op: this.OP_PLUS, right: this.form.number, result: this.OP_BLANK})
        }

        if (this.form.add) {
            this.items = [...this.items, ...add]
        }
        if (this.form.cut) {
            this.items = [...this.items, ...cut]
        }

        this.items.shuffle()
        this.items.splice(this.form.lineNumber)
    }

}
