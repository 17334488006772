// Fisher–Yates shuffle
Object.defineProperty(Array.prototype, 'shuffle', {
    value: function() {
        for (let i = this.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [this[i], this[j]] = [this[j], this[i]];
        }
        return this;
    }
});

export let getRandomInt = function(max: number) {
    return Math.floor(Math.random() * Math.floor(max));
}

export let getRandomIntInRange = function(min: number, max: number) {
    min = Math.ceil(min)
    max = Math.floor(max)
    return Math.floor(Math.random() * (max - min + 1)) + min
}

export let getRandomElem = function(array: any) {
    const min = 0
    const max = array.length - 1
    const elemIndex = getRandomIntInRange(min, max)
    return array[elemIndex]
}

export let getRandomDecimalInRange = function(min: number, max: number, decs: number) {
    if (decs < 1) {
        return getRandomIntInRange(min, max)
    }
    let decimals = Math.random().toFixed(decs)
    while (parseFloat(decimals) === 0 || parseFloat(decimals) === 1) {
        decimals = Math.random().toFixed(decs)
    }
    let res = getRandomIntInRange(min, max) + parseFloat(decimals)

    return res.toFixed(decs)
}

export class RandomEqui1 {
    private data: any = []
    private min: number
    private max: number
    private size: number
    private current: number = 0

    constructor(min: number, max: number, size: number) {
        this.min = min
        this.max = max
        this.size = size
        this.initData()
    }

    private initData() {
        let optionsNumber = this.max - this.min + 1
        let numberByOptions: number
        let rest: number

        if (optionsNumber >= this.size) {
            numberByOptions = 1
            rest = 0
        } else {
            numberByOptions = Math.floor(this.size / optionsNumber)
            rest = this.size % optionsNumber
        }

        for (let i = this.min; i < this.max; i++) {
            for (let j = 0; j < numberByOptions; j++) {
                this.data.push(i)
            }
        }
        for (let i = 0; i < rest; i++) {
            let rand = getRandomIntInRange(this.min, this.max)
            this.data.push(rand)
        }

        this.data.shuffle()
        console.log(this.data)
    }
    
    public getNext() {
        if (this.current >= this.size) {
            console.error("Bad usage of RandomEquiGen, you can generate only SIZE numbers")
            return NaN
        }
        console.log(this.current)
        return this.data[this.current++]
    }
    
}

export class RandomEqui2 {
    private data: any = []
    private options: number []
    private size: number
    private current: number = 0

    constructor(options: number[], size: number) {
        this.options = options
        this.size = size
        this.initData()
    }

    private initData() {
        let optionsNumber = this.options.length
        let numberByOptions: number
        let rest: number

        if (optionsNumber === 0) {
            this.size = 0
            return
        }

        if (optionsNumber >= this.size) {
            numberByOptions = 1
            rest = 0
        } else {
            numberByOptions = Math.floor(this.size / optionsNumber)
            rest = this.size % optionsNumber
        }

        for (let i = 0; i < optionsNumber; i++) {
            for (let j = 0; j < numberByOptions; j++) {
                this.data.push(this.options[i])
            }
        }
        for (let i = 0; i < rest; i++) {
            let rand = getRandomIntInRange(0, optionsNumber - 1)
            this.data.push(this.options[rand])
        }

        this.data.shuffle()
    }
    
    public getNext() {
        if (this.current >= this.size) {
            console.error("Bad usage of RandomEquiGen, you can generate only SIZE numbers")
            return 0
        }
        return this.data[this.current++]
    }
    
}
