import Vue from 'vue'
import VueRouter from 'vue-router'
import Tools from '../views/Tools.vue'
import Exercises from '@/components/Exercises.vue'
import TableGen from '@/components/generators/maths/TableGen.vue'
import ComplementGen from '@/components/generators/maths/ComplementGen.vue'
import InverseTableGen from '@/components/generators/maths/InverseTableGen.vue'
import TenGen from '@/components/generators/maths/TenGen.vue'
import ElevenGen from '@/components/generators/maths/ElevenGen.vue'
import ChampionConjugaison from '@/components/generators/french/conjugaison.vue'
import Addition from '@/components/generators/maths/Addition.vue'
import Substraction from '@/components/generators/maths/Substraction.vue'
import Divider from '@/components/generators/maths/Divider.vue'
import DividerCriteria from '@/components/generators/maths/DividerCriteria.vue'
import Half from '@/components/generators/maths/Half.vue'
import Addcut from '@/components/generators/maths/Addcut.vue'
import AddcutDecimal from '@/components/generators/maths/decimal/AddcutDecimal.vue'
import AddcutTwoDecimals from '@/components/generators/maths/decimal/AddcutTwoDecimals.vue'
import DivResDecimal from '@/components/generators/maths/decimal/DivResDecimal.vue'
import DivMultDecimal from '@/components/generators/maths/decimal/DivMultDecimal.vue'
import ComplementDecimal from '@/components/generators/maths/decimal/ComplementDecimal.vue'
import Pages from '@/components/Pages.vue'

Vue.use(VueRouter)

export const mathsTools = [
  {
    path: '/gen/table',
    name: 'TableGen',
    component: TableGen,
    title: "Générateur de tables de multiplication", keywords: ["multiplications", "tables"]
  },
  {
    path: '/gen/complement',
    name: 'ComplementGen',
    component: ComplementGen,
    title: "Générateur de compléments", keywords: ["compléments", "100", "1000", "centaines", "milliers", "additions"]
  },
  {
    path: '/gen/division',
    name: 'InverseTableGen',
    component: InverseTableGen,
    title: "Générateur de divisons sans reste (table 2 à 9)", keywords: ["divisions", "tables", "sans reste"]
  },
  {
    path: '/gen/ten',
    name: 'TenGen',
    component: TenGen,
    title: "Générateur de multiplications par des multiples de 10", keywords: ["multiplications", "multiples", "multiples de 10", "10"]
  },
  {
    path: '/gen/eleven',
    name: 'ElevenGen',
    component: ElevenGen,
    title: "Générateur de multiplications par 11", keywords: ["multiplications", "multiples", "multiples de 11", "11"]
  },
  {
    path: '/gen/addition',
    name: 'Addition',
    component: Addition,
    title: "Générateur d'additions", keywords: ["additions"]
  },
  {
    path: '/gen/substraction',
    name: 'Substraction',
    component: Substraction,
    title: "Générateur de soustractions", keywords: ["retrancher", "soustractions"]
  },
  {
    path: '/gen/divider',
    name: 'Divider',
    component: Divider,
    title: "Ce nombre est-il divisible par X ?", keywords: ["divisions", "divisibles", "sans reste"]
  },
  {
    path: '/gen/dividerCriteria',
    name: 'DividerCriteria',
    component: DividerCriteria,
    title: "Critères de divisibilité", keywords: ["critères", "divisibilité", "divisions", "sans reste"]
  },
  {
    path: '/gen/half',
    name: 'Half',
    component: Half,
    title: "Moitiés et doubles", keywords: ["moitiés", "doubles", "multiplications", "divisions"]
  },
  {
    path: '/gen/addcut',
    name: 'Addcut',
    component: Addcut,
    title: "Additionner ou soustraire un nombre X", keywords: ["retrancher", "ajouter", "additions", "soustractions"]
  },
  {
    path: '/gen/addcutdecimal',
    name: 'AddcutDecimal',
    component: AddcutDecimal,
    title: "Additionner ou soustraire un entier à un décimal", keywords: ["retrancher", "ajouter", "additions", "soustractions", "décimales"]
  },
  {
    path: '/gen/addcuttwodecimals',
    name: 'AddcutTwoDecimals',
    component: AddcutTwoDecimals,
    title: "Additionner ou soustraire deux nombres décimaux", keywords: ["retrancher", "ajouter", "additions", "soustractions", "décimales"]
  },
  {
    path: '/gen/divresdecimal',
    name: 'DivResDecimal',
    component: DivResDecimal,
    title: "Diviser un entier par X (et obtenir un nombre décimal)", keywords: ["divisions", "décimales"]
  },
  {
    path: '/gen/divmultdecimal',
    name: 'DivMultDecimal',
    component: DivMultDecimal,
    title: "Diviser ou multiplier un nombre décimal par X", keywords: ["divisions", "multiplications", "décimales"]
  },
  {
    path: '/gen/complementdecimal',
    name: 'ComplementDecimal',
    component: ComplementDecimal,
    title: "Calculer le complément d'un décimal à l'unité", keywords: ["compléments", "unité", "décimales"]
  }
]

export const frenchTools = [
  {
    path: '/gen/conjugaison',
    name: 'ChampionConjugaison',
    component: ChampionConjugaison,
    title: "Champion de conjugaison : présent de l'indicatif", keywords: ["conjugaison", "présent", "indicatif"]
  }
]

let mathsToolsModal: any = []
mathsTools.forEach(e => {
  mathsToolsModal.push({path: e.path.substring(1), name: e.name + 'Modal', component: e.component})
})

let frenchToolsModal: any = []
frenchTools.forEach(e => {
  frenchToolsModal.push({path: e.path.substring(1), name: e.name + 'Modal', component: e.component})
})

const routes = [...mathsTools, ...frenchTools,
  {
    path: '/',
    name: 'Tools',
    component: Tools
  },
  {
    path: '/exercises',
    name: 'Exercises',
    component: Exercises,
    children: [
      {
        path: '',
        name: 'ModalTools',
        component: Tools
      },
      ...mathsToolsModal, ...frenchToolsModal
    ]
  },
  {
    path: '/pages',
    name: 'Pages',
    component: Pages
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  },
  {
    path: '/manual',
    name: 'Manual',
    component: () => import('../views/Manual.vue')
  },
  {
    path: '/ressources',
    name: 'Ressources',
    component: () => import('../views/Ressources.vue')
  }
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
