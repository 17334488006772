





























import {Component, Vue} from 'vue-property-decorator'
import {mathsTools, frenchTools} from '@/router/index'

@Component
export default class Tools extends Vue {

  public maths: any = mathsTools
  public french: any = frenchTools

  public term: string = ''

  public getPath(base: string): string {
    return this.isExercisesActive ? "/exercises" + base : base
  }

  get isExercisesActive() {
    return this.$route.path.startsWith('/exercises')
  }

  public filter(arr: any[]) {
    if (this.term.length === 0) {
      return arr
    }

    return arr.filter(line => line.keywords.filter(keyword => keyword.toLowerCase().startsWith(this.term.toLowerCase())).length > 0
                      || line.title.toLowerCase().startsWith(this.term.toLowerCase()))
  }
}
