








































import { Component, Vue } from 'vue-property-decorator';
import CardResult from '@/components/CardResult.vue'
import { getRandomDecimalInRange } from  '@/plugins/maths';

@Component({
    metaInfo() { return { title: "Additionner ou soustraire deux nombres décimaux" } },
    components: {CardResult}
})
export default class AddcutTwoDecimals extends Vue {

    private OP_PLUS: string = " + "
    private OP_MINUS : string = " - "
    private OP_EQUALS: string = " = "
    private OP_BLANK: string = ". . ."

    public form: any = {}
    public items: any = []
    public show: boolean = true

    public created() {
        this.reset()
    }

    public onSubmit(evt: any) {
        evt.preventDefault()
        this.generate()
        this.$emit('newLines', this.items)
    }

    public reset() {
        this.form = {
            lineNumber: 10,
            min: 1,
            max: 100,
            cut: true,
            add: false
         }
    }

    public onReset(evt: any) {
        evt.preventDefault()
        this.reset()
        // Trick to reset/clear native browser form validation state
        this.show = false
        this.$nextTick(() => {
            this.show = true
        })
    }

    public generate() {
        let add: {}[] = []
        for (let i = 0; i < this.form.lineNumber; i++) {
            let left = getRandomDecimalInRange(this.form.min, this.form.max, 1)
            let right = getRandomDecimalInRange(this.form.min, this.form.max, 1)
            add.push({ left: left, op: this.OP_PLUS, right: right, result: this.OP_BLANK})
        }            

        let cut: {}[] = []
        for (let i = 0; i < this.form.lineNumber; i++) {
            let left: any = getRandomDecimalInRange(this.form.min, this.form.max, 1)
            let leftDecPart: number = left - Math.floor(left)
            let right: any = getRandomDecimalInRange(this.form.min, this.form.max, 1)
            let rightDecPart: number = right - Math.floor(right)
            while (parseFloat(left) < parseFloat(right) || leftDecPart < rightDecPart) {
                left = getRandomDecimalInRange(this.form.min, this.form.max, 1)
                leftDecPart = left - Math.floor(left)
                right = getRandomDecimalInRange(this.form.min, this.form.max, 1)
                rightDecPart = right - Math.floor(right)
            }
            cut.push({ left: left, op: this.OP_MINUS, right: right, result: this.OP_BLANK })
        }

        this.items = []
        if (this.form.add) {
            this.items = [...this.items, ...add]
        }
        if (this.form.cut) {
            this.items = [...this.items, ...cut]
        }

        this.items.shuffle()
        this.items.splice(this.form.lineNumber)
    }

}
