import { Component, Vue } from 'vue-property-decorator';
import { getRandomInt, getRandomElem } from  '@/plugins/maths';
import CardResult from '@/components/CardResult.vue'

@Component({
    metaInfo() { return { title: "Divisions sans reste" } },
    components: {CardResult}
})
export default class InverseTableGen extends Vue {

    private OP_DIVIDE: string = " ÷ "
    private OP_EQUALS: string = " = "
    private OP_BLANK: string = ". . . ."

    public form: any = {}
    public show: boolean = true
    public tablesOptions = [
        { text: '2', value: 2 },
        { text: '3', value: 3 },
        { text: '4', value: 4 },
        { text: '5', value: 5 },
        { text: '6', value: 6 },
        { text: '7', value: 7 },
        { text: '8', value: 8 },
        { text: '9', value: 9 }
      ]
      public tablesSelected: number[] = []
      public items: any = []
      private multiplier: number[] = [2,3,4,5,6,7,8,9,10]

      public created() {
        this.reset()
      }
      
      public onSubmit(evt: any) {
        evt.preventDefault()
        if (this.tablesSelected.length === 0) {
          return
        }
        this.generateTable()
        this.$emit('newLines', this.items)
      }

      public reset() {
        this.form = {
          lineNumber: 10,
          doNotUseDuplicate: false
        }
        this.tablesSelected = []
      }

      public onReset(evt: any) {
        evt.preventDefault()
        this.reset()
        // Trick to reset/clear native browser form validation state
        this.show = false
        this.$nextTick(() => {
          this.show = true
        })
      }

      public generateTable() {
        this.items = []
        let multiplier = [...this.multiplier]
        if (this.form.doNotUseDuplicate) {
          this.tablesSelected.forEach(table => {
            multiplier.forEach(mult => {
              let result = mult * table
              this.items.push({left: result, op: this.OP_DIVIDE, right: table, result: this.OP_BLANK})
            })
          })
          this.items =  this.items.shuffle().slice(0, this.form.lineNumber)
        } else {
          for (let i = 0; i < this.form.lineNumber; i++) {
            let randTable = this.tablesSelected[getRandomInt(this.tablesSelected.length)]
            let mult = getRandomElem(multiplier)
            let result = mult * randTable
            this.items[i] = {left: result, op: this.OP_DIVIDE, right: randTable, result: this.OP_BLANK}
          }
        }
      }
}
