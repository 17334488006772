export interface ILayout {
    x: number
    y: number
    w: number
    h: number
    exoId: number
    active: boolean
    i?: string
  }
  
  export class Layout implements ILayout {
    constructor(
      public x: number,
      public y: number,
      public w: number,
      public h: number,
      public exoId: number,
      public active: boolean,
      public i?: string
    ) {
        if (!i) {
          this.i = (Date.now() + Math.random()).toString()
        }
    }
  }
