








import {Component, Vue} from 'vue-property-decorator'
import Menu from '@/components/Menu.vue'
import Footer from '@/components/Footer.vue'

@Component({
    metaInfo() {
      return {
        title: 'Edutools',
        meta: [
          { name: 'description', content: "Générateurs d'exercices et de pages à imprimer. Des outils pour les enseignants des niveaux CE1, CE2, CM1 et CM2." }
        ]
      }
    },
    components: {Menu, Footer}
})
export default class App extends Vue {}
