



























































import { Component, Vue } from 'vue-property-decorator';
import { getRandomIntInRange } from  '@/plugins/maths';
import CardResult from '@/components/CardResult.vue'

@Component({
    metaInfo() { return { title: "Générateur de soustractions" } },
    components: {CardResult}
})
export default class Substraction extends Vue {

    private OP_MINUS: string = " - "
    private OP_BLANK: string = ". . ."

    public form: any = {}
    public items: any = []
    public show: boolean = true
    public advanced: boolean = false

    public created() {
        this.reset()
    }

    public onSubmit(evt: any) {
        evt.preventDefault()
        this.generate()
        this.$emit('newLines', this.items)
    }

    public reset() {
        this.form = {
            lineNumber: 10,
            one: true,
            two: false,
            three: false,
            shuffle: false,
            adv: [{right: {min: 1, max: 1}, left: {min: 1, max: 1}, lines: 10}]
         }
    }

    public onReset(evt: any) {
        evt.preventDefault()
        this.reset()
        // Trick to reset/clear native browser form validation state
        this.show = false
        this.$nextTick(() => {
            this.show = true
        })
    }

    public addAdvParam() {
        this.form.adv.push({right: {min: 2, max: 2}, left: {min: 2, max: 2}, lines: 10})
    }

    public removeLastAdvParam() {
        this.form.adv.pop()
    }

    public generate() {
        this.items = []

        if (this.advanced) {
            this.generateAdvanced()
        } else {
            this.generateSimple()
        }

        if (this.form.shuffle) {
            this.items.shuffle()
        }
    }

    private generateSimple() {
        let optionsNumber = [this.form.one, this.form.two, this.form.three].filter(Boolean).length;
        let lines = {
            number: Math.floor(this.form.lineNumber/optionsNumber),
            rest: this.form.lineNumber % optionsNumber,
            restUsed: false
        }
        
        if (this.form.one) {
            this.addLinesToItems(this.getLines(lines), {min: 1, max: 1}, {min: 1, max: 1});
        }
        if (this.form.two) {
            this.addLinesToItems(this.getLines(lines), {min: 2, max: 2}, {min: 2, max: 2});
        }
        if (this.form.three) {
            this.addLinesToItems(this.getLines(lines), {min: 3, max: 3}, {min: 3, max: 3});
        }
    }

    private generateAdvanced() {
        for (let i = 0; i < this.form.adv.length; i++) {
            this.addLinesToItems(this.form.adv[i].lines, this.form.adv[i].left, this.form.adv[i].right)
        }
    }

    private getLines(lines: any) {
        if (lines.rest > 0 && !lines.restUsed) {
            lines.restUsed = true
            return lines.number + lines.rest
        }
        return lines.number
    }

    private addLinesToItems(lines: number, leftParam: {min: number, max: number}, rightParam: {min: number, max: number}) {
        let arr = [{}]
        for (let i = 0; i < lines; i++) {
            let leftRange = {min: Math.pow(10, leftParam.min - 1), max: Math.pow(10, leftParam.max) -1}
            let rightRange = {min: Math.pow(10, rightParam.min - 1), max: Math.pow(10, rightParam.max) -1}
            let res = this.getRandLeftSupRight(leftRange, rightRange)
            arr[i] = { left: res.left, op: this.OP_MINUS, right: res.right, result: this.OP_BLANK };
        }
        this.items = [...this.items, ...arr];
    }

    private getRandLeftSupRight(leftRange: {min: number, max: number}, rightRange: {min: number, max: number}) {
        let left = getRandomIntInRange(leftRange.min, leftRange.max)
        let right = getRandomIntInRange(rightRange.min, rightRange.max)
        while (left < right) {
            left = getRandomIntInRange(leftRange.min, leftRange.max)
            right = getRandomIntInRange(rightRange.min, rightRange.max)
        }
        return {right: right, left: left}
    }
}
