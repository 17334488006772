import { ISection } from '@/models/Section';
import { Theme, ExerciseFormat } from '@/models/Enums';

export interface IExercise {
  id?: number
  title?: string
  subtitle?: string
  sections?: ISection[],
  theme?: string,
  type?: string
}

export class Exercise implements IExercise {
  constructor(
    public id?: number,
    public title?: string,
    public subtitle?: string,
    public sections?: ISection[],
    public theme?: string,
    public type?: string
  ) {
    this.sections = []
    this.id = generateId()
    this.theme = Theme.MALIN.value
    this.type = ExerciseFormat.DEMI.value
  }
}

export function generateId(): number {
  return Date.now() + Math.random()
}
