import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { IPage, Page } from '@/models/Page';

@Module({ namespaced: true })
export default class Pages extends VuexModule {
  public pages: Array<IPage> = []
  public currentPage: number = 0

  @Mutation
  public updateCurrentPage(id: number) {
    this.currentPage = id
  }

  @Mutation
  public addPage(page: Page) {
    this.pages.push(page)
  }

  @Mutation
  public updatePage(page: Page) {
    let index = this.pages.findIndex(p => p.id === page.id)
    if (index > -1) {
      this.pages[index] = page
    }
  }

  @Mutation
  public delPage(page: Page) {
    let index = this.pages.findIndex(p => p.id === page.id)
    if (index > -1) {
      this.pages.splice(index, 1)
    }
  }

  @Action
  public savePage(page: Page) {
    let index = this.pages.findIndex(p => p.id === page.id)
    if (index > -1) {
      this.context.commit('updatePage', page)
    } else {
      this.context.commit('addPage', page)
    }
  }

  @Action
  public deletePage(page: Page) {
    return new Promise((resolve, reject) => {
      let index = this.pages.findIndex(p => p.id === page.id)
      if (index > -1) {
        this.context.commit('delPage', page)
        resolve()
      } else {
        reject()
      }
    })
  }

  get getPage() {
    return (id: number) => {
      let index = this.pages.findIndex(p => p.id === id)
      if (index > -1) {
          return this.pages[index]
      }
      return {}
    }
  }

  get getPages() {
      return (ids: number[]) => {
        return ids.map(id => this.getPage(id))
    };
  }
}
