import { SectionFormat } from '@/models/Enums';

export interface ISection {
  id?: number
  title?: string
  subtitle?: string
  lines?: any[]
  type?: string
}

export class Section implements ISection {
  constructor(
    public id?: number,
    public title?: string,
    subtitle?: string,
    public lines?: any[],
    public type?: string
  ) {
    this.type = SectionFormat.SIMPL.value
  }
}
