


















































import {Component, Vue, Prop} from 'vue-property-decorator'
import LinesTable from '@/views/LinesTable.vue'

@Component({
    components: {LinesTable}
})
export default class ExercisePreview extends Vue {
    @Prop() readonly exercise!: any

    public OP_EQUALS: string = " = "

    public themes = {
        'flash': {'icon':'lightning'},
        'malin': {'icon':'hourglass-split'},
        'strat': {'icon':'calculator'},
        'problem': {'icon':'play'},
        'simple': {'icon':''},
        'eco': {'icon': ''}
    }
}
