import { Component, Vue } from 'vue-property-decorator';
import ExercisePreview from '@/components/ExercisePreview.vue'
import { IExercise, Exercise, generateId } from '@/models/Exercise';
import { Section } from '@/models/Section';
import draggable from 'vuedraggable'
import ContentSaveOutlineIcon from 'vue-material-design-icons/ContentSaveOutline.vue';
import DeleteOutlineIcon from 'vue-material-design-icons/DeleteOutline.vue';
import PlusCircleOutlineIcon from 'vue-material-design-icons/PlusCircleOutline.vue';
import FolderOutlineIcon from 'vue-material-design-icons/FolderOutline.vue';
import PrinterIcon from 'vue-material-design-icons/Printer.vue';
import CloneIcon from 'vue-material-design-icons/ArrangeSendToBack.vue'
import { Theme, ExerciseFormat, SectionFormat } from '@/models/Enums';
import ModalPrint from '@/views/ModalPrint.vue'

@Component({
  metaInfo() {
    return {
      title: 'Créer un exercice',
      meta: [
        { name: 'description', content: "Créer et imprimer un exercice facilement et rapidement. L'exercice est mis en forme automatiquement." }
      ]
    }
  },
  components: {
    CloneIcon, ExercisePreview, draggable, ContentSaveOutlineIcon, PlusCircleOutlineIcon, 
    DeleteOutlineIcon, FolderOutlineIcon, PrinterIcon, ModalPrint
  }
})
export default class Exercises extends Vue {

  public exercise: IExercise = new Exercise()
  public newItems: any = []
  public sectionSelected: number = 0
  public optionsTheme = Theme
  public optionsFormat = ExerciseFormat
  public optionsSection = SectionFormat
  private exoState = this.$store.state.Exercises

  public created() {
    if (this.currentExercise) {
        let foundExercise = this.exercises.find(page => page.id === this.currentExercise)
        if (foundExercise) {
            this.exercise = foundExercise
        }
    }
    this.currentExercise = this.exercise.id!
  }

  get currentExercise(): number {
    return this.exoState.currentExercise
  }
  set currentExercise(id) {
    this.$store.commit('Exercises/updateCurrentExercise', id)
  }

  get exercises(): IExercise[] {
    return this.exoState.exercises
  }
  
  public updateExercise() {
    this.$store.dispatch('Exercises/saveExercise', this.exercise)
  }

  public addSection() {
    this.exercise.sections!.push(new Section())
  }

  public onNewLines(items: any[]) {
    this.newItems = items
  }

  public useLines() {
    if (this.newItems.length > 0) {
      let section: Section = this.exercise.sections![this.sectionSelected]
      section.lines = this.newItems
      Vue.set(this.exercise.sections!, this.sectionSelected, section)
    }
    this.updateExercise()
  }

  public back() {
      this.$router.push({name: 'ModalTools'})
  }

  public removeSection(indexSection: number) {
    this.exercise.sections?.splice(indexSection, 1)
    this.updateExercise()
  }

  // TOOLBAR FEATURES //

  public loadExerciseIndex(index: number) {
    if (index <= this.exercises.length) {
      this.exercise = this.exercises[index]
      this.currentExercise = this.exercise.id!
    }
  }

  public deleteExercise() {
    this.$store.dispatch('Exercises/deleteExercise', this.exercise).then(() => {
      this.showToast('L\'exercice a été supprimé !')
      this.newExercise()
    }).catch(() => {
        this.showToast('Il n\'y a rien a supprimer.')
    })
  }

  public newExercise() {
    this.exercise = new Exercise()
    this.currentExercise = this.exercise.id!
  }

  public cloneExercise() {
    this.exercise = JSON.parse(JSON.stringify(this.exercise))
    this.exercise.id = generateId()
    this.exercise.title += " CLONE"
    this.currentExercise = this.exercise.id!
    this.showToast('L\'exercice a été cloné.')
    this.updateExercise()
  }

  public openPrinter() {
    let modalPrintMustbeShown = !this.$store.state.modalPrintShown
    if (modalPrintMustbeShown && navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
      this.$bvModal.show('modal-warn-print')
    } else {
      window.print()
    }
  }

  // UTILITY //

  private showToast(text: string) {
    Vue.toasted.show(text, {duration: 1500, position: 'top-center', theme: 'bubble'});
  }
}
