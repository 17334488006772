import Vuex from 'vuex'
import Vue from 'vue'
import Exercises from "./modules/exercises.module";
import Pages from "./modules/pages.module";

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    Exercises,
    Pages
  },

  state: {
    modalPrintShown: false,
    version: 1
  },
  
  getters: {

  },
  
  actions: {
    
  },
  
  mutations: {
    initialiseStore(state) {
      let tmp = localStorage.getItem('store')
      if (tmp) {
        this.replaceState(Object.assign(state, JSON.parse(tmp)));
      }
    },
    setModalPrintShown(state) {
      state.modalPrintShown = true
    }
  }

})
