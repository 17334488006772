import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import router from './router'
import VueClipboard from 'vue-clipboard2'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import Toasted from 'vue-toasted';
import store from '@/store/index'
import Fragment from 'vue-fragment'
import VueAnalytics from 'vue-analytics';
import VueMeta from 'vue-meta'

// Subscribe to store updates
store.subscribe((mutation, state) => {
	// Store the state object as a JSON string
	localStorage.setItem('store', JSON.stringify(state));
});

Vue.prototype.$scrollToTop = () => window.scrollTo(0,0)


Vue.use(VueMeta, {
  // optional pluginOptions
})

// Configuration VueAnalytics
// Vue.use(VueAnalytics, {
//   id: 'UA-185634856-1',
//   router
// });

Vue.use(Fragment.Plugin)
Vue.use(Toasted)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(VueClipboard)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  beforeCreate() {
    this.$store.commit('initialiseStore');
	},
  render: h => h(App)
}).$mount('#app')
