




























import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Menu extends Vue { 
    get isToolsActive() {
        return this.$route.path === '/' || this.$route.path.startsWith('/gen')
    }
    get isExercisesActive() {
        return this.$route.path.startsWith('/exercises')
    }
    get isPagesActive() {
        return this.$route.path.startsWith('/pages')
    }
    get isRessourcesActive() {
        return this.$route.path.startsWith('/ressources')
    }
}
