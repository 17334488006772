








































import { Component, Vue } from 'vue-property-decorator';
import CardResult from '@/components/CardResult.vue'
import { getRandomIntInRange } from  '@/plugins/maths';

@Component({
    metaInfo() { return { title: "Moitiés et doubles" } },
    components: {CardResult}
})
export default class Half extends Vue {

    private OP_DIVIDE: string = " ÷ "
    private OP_EQUALS: string = " = "
    private OP_BLANK: string = ". . ."

    public form: any = {}
    public items: any = []
    public show: boolean = true

    public created() {
        this.reset()
    }

    public onSubmit(evt: any) {
        evt.preventDefault()
        this.generate()
        this.$emit('newLines', this.items)
    }

    public reset() {
        this.form = {
            lineNumber: 10,
            min: 1,
            max: 100,
            half: true,
            double: false
         }
    }

    public onReset(evt: any) {
        evt.preventDefault()
        this.reset()
        // Trick to reset/clear native browser form validation state
        this.show = false
        this.$nextTick(() => {
            this.show = true
        })
    }

    public generate() {
        this.items = []

        let half: {}[] = []
        let double: {}[] = []
        for (let i = 0; i < this.form.lineNumber; i++) {
            let number = getRandomIntInRange(this.form.min, this.form.max)
            if (number % 2 !== 0) {
                number++
            }
            half.push({ left: "La moitié de " + number + " est ...." })

            number = getRandomIntInRange(this.form.min, this.form.max)
            double.push({ left: "Le double de " + number + " est ...." })
        }

        if (this.form.half) {
            this.items = [...this.items, ...half]
        }
        if (this.form.double) {
            this.items = [...this.items, ...double]
        }

        this.items.shuffle()
        this.items.splice(this.form.lineNumber)
    }

}
